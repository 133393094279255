/* eslint-env browser */

import React, { useState } from 'react';

function openUrlInNewTab(url) {
  if (window) window.open(url, '_blank');
}

function copyEmail() {
  try {
    const copyText = document.querySelector('#invisible-email-input');
    copyText.select();
    document.execCommand('copy');

    const tooltip = document.querySelector('#contact-tooltip');
    tooltip.style.display = 'block';

    setTimeout(() => tooltip.style.display = 'none', 5000);
    return true;
  } catch (e) {
    return false;
  }
}

function openEmail() {
  if (window) window.location.href = 'mailto:wedding@tayloranddavid.club';
}

function Menu({ activePage }) {
  function activePageClass(page) {
    return page === activePage ? 'active' : '';
  }

  const [isNavOpen, setIsNavOpen] = useState(false);

  function changePage(newPage) {
    setIsNavOpen(false);
    if (window) window.location.hash = newPage;
  }

  return <nav className="menu">
    <div className="mobile-btns">
      <span className='flower-icon'> <img src='images/flower.png'></img></span>
      <button className='menu-btn' onClick={() => setIsNavOpen(!isNavOpen)} type="button">Menu</button>
    </div>
    <ol className={isNavOpen ? '' : 'hidden'}>
      <li className={`${activePageClass('home')}`} onClick={() => changePage('home') }>Home</li>
      <li className={`${activePageClass('our-wedding')}`} onClick={() => changePage('our-wedding') }>Our Wedding</li>
      <li className={`${activePageClass('our-party')}`} onClick={() => changePage('our-party') }>Our Party</li>
      <li className={`${activePageClass('rsvp')}`} onClick={() => changePage('rsvp') }>RSVP</li>
      <li onClick={() => openUrlInNewTab('https://www.zola.com/registry/teb-dmr?preview=true') }>Registry</li>
      <li className={`${activePageClass('contact')}`} onClick={() => copyEmail() || openEmail()}>
        Contact
        <div id="contact-tooltip">
          Our email address,<br></br>
          <strong>wedding@tayloranddavid.club</strong>,<br></br>
          was copied to your clipboard!
        </div>
      </li>
    </ol>
    <input readOnly id='invisible-email-input' type='text-box' value='wedding@tayloranddavid.club' style={{ position: 'absolute', top: '-10000px' }}></input>
  </nav>;
}

export default Menu;
