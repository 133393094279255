/* eslint-env browser */

import React, { useRef, useState } from 'react';

const formCollectionScriptURL = 'https://script.google.com/macros/s/AKfycbxorQ_QoBjQ00gWq0AvivzNefD7RYdQGza6zCqYWxHdX90gAt8g0WLQw4E6TMmpKM4L/exec';

const eventDetails = {
  // MARDI_GRAS_PARTY: {
  //   prettyName: '',
  //   description: '',
  //   time: '',
  //   location: '',
  // },
  FRI_DINNER: {
    prettyName: 'Welcome Dinner @ Grow Dat Youth Farm',
    description: 'Enjoy a sit-down dinner with close friends and family',
    time: 'Friday, 6pm - 8pm',
    location: '150 Zachary Taylor Dr, New Orleans, LA 70124',
  },
  FRI_PARTY: {
    prettyName: 'Welcome Drinks @ Grow Dat Youth Farm',
    description: 'Schmooz with all our guests',
    time: 'Friday, 8pm - till',
    location: '150 Zachary Taylor Dr, New Orleans, LA 70124',
  },
  SAT_HAPPY_HOUR: {
    prettyName: 'Family Happy Hour @ Felicity Church',
    description: 'Enjoy drinks and snacks with family before the big party',
    time: 'Saturday, 5pm - 6pm',
    location: '1220 Felicity St, New Orleans, LA 70130',
  },
  SAT_PARTY: {
    prettyName: 'The Big Party!! @ Felicity Church',
    description: 'The big event! Come ready to eat, drink, dance, and celebrate',
    time: 'Saturday, 6pm - 10pm',
    location: '1220 Felicity St, New Orleans, LA 70130',
  },
  SUN_BRUNCH: {
    prettyName: 'Bayou Beverages and Goodbyes @ The Billings Household',
    description: 'Come for tacos and one last hang before the weekend is over',
    time: 'Sunday, 11am - till',
    location: '1448 Moss St, New Orleans, LA 70119',
  },
};

function EventInfoSection({ eventKey }) {
  const [isSelected, setIsSelected] = useState(false);
  const eventInfo = eventDetails[eventKey];

  if (!eventInfo) return null;

  return (
    <li key={eventKey} className={`event_info ${isSelected ? 'selected' : ''}`} onClick={() => setIsSelected(!isSelected)}>
      <h4>{eventInfo.prettyName}</h4>
      <h5>{eventInfo.description}</h5>
      <p>{eventInfo.time} <span className="separator">&middot;</span> {eventInfo.location}</p>

      <div className="checkbox_wrapper">
        <label htmlFor={`RSVP_${eventKey}`}>Will we see you there?</label>
        <input id={`RSVP_${eventKey}`} name={`RSVP_${eventKey}`} type="checkbox" readOnly checked={isSelected}/>
      </div>
    </li>
  );
}

function RSVP() {
  const [isLoading, setIsLoading] = useState(false);
  const [successfullyRSVPed, setSuccessfullyRSVPed] = useState(false);
  const [events, setEvents] = useState([]);
  const [guests, setGuests] = useState([]);
  const [givenFirstName, setGivenFirstName] = useState(null);
  const [showSubmissionError, setShowSubmissionError] = useState(null);

  const ref = useRef(null);

  const fetchWithRetries = function fetchWithRetriesFn(url, requestOpts, numberTries = 0) {
    if (numberTries < 3) {
      return window.fetch(url, requestOpts)
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            throw new Error(response.error);
          } else {
            return response;
          }
        })
        .catch(() => fetchWithRetries(url, requestOpts, numberTries + 1));
    }
    throw new Error('max retries reached');
  };

  function handleSubmit(e) {
    setIsLoading(true);
    e.preventDefault();

    if (guests.length) {
      const rsvpEls = ref.current.querySelectorAll('.events_list input');

      Promise.all(Array.from(ref.current.querySelectorAll('.guest_info')).map((guestInfo) => {
        const requestData = new FormData();
        requestData.append('Form Type', 'Confirm Reservation');

        let firstName; let lastName; let
          isNotComing = false;
        guestInfo.querySelectorAll('input, textarea').forEach((formEl) => {
          if (formEl.name === 'First Name') {
            firstName = formEl.value;
          }
          if (formEl.name === 'Last Name') {
            lastName = formEl.value;
          }
          if (firstName && lastName && formEl.id === `input_can_you_make_it_no_${firstName}_${lastName}` && formEl.checked) {
            isNotComing = true;
          }
          if (formEl.classList.contains('input_vaccination_status')) {
            requestData.append(formEl.name, formEl.checked);
          } else {
            requestData.append(formEl.name, formEl.value);
          }
        });

        rsvpEls.forEach((rsvpEl) => requestData.append(
          rsvpEl.name,
          isNotComing ? false : rsvpEl.checked,
        ));

        const mardiGrasCheck = ref.current.querySelector('.rsvp_mardi_gras input');
        requestData.append('Mardi Gras Week', mardiGrasCheck.checked);

        const otherInfoText = ref.current.querySelector('.anything_else').value;
        requestData.append('Other Info', otherInfoText);

        return fetchWithRetries(formCollectionScriptURL, { method: 'POST', body: requestData })
          .then((response) => {
            if (response.error) {
              throw new Error('we were unable to accept your form submission. please try again, or let us know at weddings@tayloranddavid.club');
            } else {
              return Promise.resolve();
            }
          });
      })).then(() => {
        setIsLoading(false);
        setShowSubmissionError(null);
        setSuccessfullyRSVPed(true);
      }).catch((error) => {
        setIsLoading(false);
        setSuccessfullyRSVPed(false);
        console.error('Error!', error.message);
        setShowSubmissionError(error.message);
      });
    } else {
      let parsedFirstName = ref.current.querySelector('input[name="First Name"]').value.trim();
      parsedFirstName = parsedFirstName.slice(0, 1).toUpperCase() + parsedFirstName.slice(1);
      setGivenFirstName(parsedFirstName);
      fetchWithRetries(formCollectionScriptURL, { method: 'POST', body: new FormData(ref.current) })
        .then((response) => {
          setIsLoading(false);
          if (response.error) {
            throw new Error(JSON.stringify(response));
          }

          if (response.guests && response.guests.length === 0) {
            setShowSubmissionError('Sorry, the above name was not found. Please try and submit as written on your invitation');
            return;
          }
          setShowSubmissionError(null);

          setGuests(response.guests);
          setEvents(response.events);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error!', error.message);
          setShowSubmissionError(error.message);
        });
    }
  }

  function renderInitialForm() {
    return (
      <>
        <input name="Form Type" hidden readOnly value="Find Guests"/>
        <h2>Welcome! Please submit your first and last name</h2>
        <div className="input-group">
          <label htmlFor="input-first-name">First Name: </label>
          <input id="input-first-name" name="First Name" type="text" required/>
        </div>
        <div className="input-group">
          <label htmlFor="input-last-name">Last Name: </label>
          <input id="input-last-name" name="Last Name" type="text" required/>
        </div>
      </>
    );
  }

  function renderRSVPForm() {
    return (
      <>
        <input name="Form Type" hidden readOnly value="Confirm Reservation"/>
        <h2>Hi{givenFirstName ? `, ${givenFirstName}` : ''}!</h2>
        <p>Please fill out the form below {guests.length > 1 ? 'for you and your guest(s) ' : ''}to complete your RSVP</p>

        <div className="rsvp_section">
          <h3>Guest Information</h3>
          <ul className="guests_list">
          {guests.map(({ first, last }) => (<li className="guest_info" key={`${first} ${last}`}>
            <h4>{first} {last}</h4>
            <input name="First Name" type="text" hidden readOnly value={first}/>
            <input name="Last Name" type="text" hidden readOnly value={last}/>
            <input name="Email" type="email" placeholder="Email Address"/>
            <div className="checkbox_wrapper can_you_attend">
              <p>Are you able to attend the weekend's festivities?</p>
              <input type="radio" id={`input_can_you_make_it_yes_${first}_${last}`} name={`Can You Make It ${first} ${last}`} defaultChecked/>
              <label htmlFor={`input_can_you_make_it_yes_${first}_${last}`}>Yes</label>
              <input type="radio" id={`input_can_you_make_it_no_${first}_${last}`} name={`Can You Make It ${first} ${last}`}/>
              <label htmlFor={`input_can_you_make_it_no_${first}_${last}`}>No</label>
            </div>
            <div className="checkbox_wrapper">
              <label htmlFor={`input_vaccination_status_${first}_${last}`}>Have you received the COVID-19 vaccine?</label>
              <input className="input_vaccination_status" id={`input_vaccination_status_${first}_${last}`} name="Vaccination Status" type="checkbox"/>
            </div>
            <p className="vaccination_disclaimer">
              The City of New Orleans requires proof of vaccination for large gatherings, and we're doing the same for ours.
              Please bring proof of vaccination so you can enjoy our party and the city!
            </p>

            <textarea name="Dietary Restrictions" type="textarea" placeholder="Any dietary restrictions we should know about?"/>
          </li>))}
          </ul>
        </div>
        <div className="rsvp_section">
          <h3>Event RSVPs</h3>
          <ul className="events_list">
            {events.map((eventKey) => <EventInfoSection key={eventKey} eventKey={eventKey}/>)}
          </ul>
        </div>

        <div className="checkbox_wrapper rsvp_mardi_gras">
          <label htmlFor="mardi_gras_ask">Do you plan on coming for Mardi Gras the week before?</label>
          <input name="Mardi Gras Week" id="mardi_gras_ask" type="checkbox"/>
        </div>
        <textarea className="anything_else" name="Other Info" type="textarea" placeholder="Anything else we need to know?"/>
      </>
    );
  }

  return <div className='rsvp-page'>
    <h1>RSVP Form</h1>
    {successfullyRSVPed
      ? <div className="form_success">
        <h2>Thanks so much! See you in March!</h2>
      </div>
      : <form name="RSVP Submission" onSubmit={handleSubmit} ref={ref}>
        {guests.length ? renderRSVPForm() : renderInitialForm()}
        <button type="submit">{isLoading ? (<div className='loader'></div>) : 'Submit'}</button>
      </form>
    }
    {showSubmissionError && (
      <>
        <p className="submission-error">{showSubmissionError}</p>
        <p>Having trouble with this form? Let us know by emailing us at <a className="rsvp-help-email" href="mailto:wedding@tayloranddavid.club">wedding at tayloranddavid.club</a></p>
      </>
    )}
  </div>;
}

export default RSVP;
