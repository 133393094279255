import React from 'react';

function filmstrip() {
  return (<article className="title-section">
  <img className='garland top-right' src='images/garland.png'></img>
  <img className='garland bottom-left' src='images/garland.png'></img>
  <h1>Taylor & David have gotten married!</h1>
</article>);
}

function Homepage() {
  return <div className='homepage'>
    <div className='photo-underlay wedding_were_cute'>

      <div className="stars_wrapper david_stars">
        <img className="star_1" src="images/star_1.png"></img>
        <img className="star_2" src="images/star_2.png"></img>
        {/* <img className="star_3" src="images/star_3.png"></img> */}
      </div>
      <div className="stars_wrapper taylor_stars">
        {/* <img className="star_1" src="images/star_1.png"></img> */}
        <img className="star_2" src="images/star_2.png"></img>
        <img className="star_3" src="images/star_3.png"></img>
      </div>
      <div className="super_hero">We got<br/>Married</div>
    </div>

  </div>;
}

export default Homepage;
