/* eslint-env browser */

import React from 'react';

import Homepage from './homepage';
import Party from './party';
import Wedding from './wedding';
import MenuOverlay from './menu';
import RSVPForm from './rsvp';

class Container extends React.Component {
  constructor(props) {
    super(props);
    if (window && !window.location.hash) window.location.hash = '#home';

    this.state = { activePage: window && window.location.hash };

    this.onHashChange = this.onHashChange.bind(this);
  }

  componentDidMount() {
    if (window) window.addEventListener('hashchange', this.onHashChange);
  }

  componentDidUnMount() {
    if (window) window.removeEventListener('hashchange', this.onHashChange);
  }

  onHashChange() {
    this.setState({ activePage: window && window.location.hash });
  }

  render() {
    const { activePage } = this.state;

    const content = [];

    switch (activePage) {
      case '#our-wedding':
        content.push(<Wedding></Wedding>);
        break;
      case '#our-party':
        content.push(<Party></Party>);
        break;
      case '#rsvp':
        content.push(<RSVPForm></RSVPForm>);
        break;
      case '#home':
      default:
        content.push(<Homepage></Homepage>);
    }

    return (
      <div id='page-container'>
        <MenuOverlay activePage={activePage}></MenuOverlay>
        <div id="container">{content}</div>
      </div>
    );
  }
}

export default Container;
