import React, { useEffect, useState } from 'react';

function generateCircle() {
  const randomHeightWidth = 24 + Math.floor((Math.random() * 10));
  const randomOpacity = 60 + Math.floor(Math.random() * 30);
  const randomTop = Math.floor(Math.random() * document.querySelector('.party_section:not(.hidden)').scrollHeight);
  const randomLeft = Math.floor(Math.random() * window.innerWidth);

  const randomWrapperStyles = {
    top: randomTop,
    left: randomLeft,
    opacity: `${randomOpacity}%`,
  };
  const randomImgStyles = {
    height: randomHeightWidth,
    width: randomHeightWidth,
  };
  return (<div className="party_circle_wrapper" style={randomWrapperStyles}><img className="party_circle_image" src="images/texture.jpg" style={randomImgStyles}/></div>);
}

function Party() {
  const [activeSection, setActiveSection] = useState('schedule');
  const [loadedCircles, loadNewCircles] = useState('');
  function generateCircles() {
    let amount;

    if (activeSection === 'schedule') {
      amount = window.innerWidth > 700 ? 200 : 100;
    } else {
      amount = window.innerWidth > 700 ? 50 : 25;
    }
    return (new Array(amount)).fill('').map(generateCircle);
  }

  useEffect(() => {
    loadNewCircles(generateCircles);
  }, [activeSection, setActiveSection]);

  return (
    <section className={'party'}>
      <header>
        <div className='party_title'>
          <h1>Our Party</h1>
          {/*
          <div className="party_lines party_lines_left">
            <svg viewBox="0 0 50 5" className="party_line"><line strokeWidth="5" stroke="blue" y2="2.5" x2="45" x1="5" y1="2.5"></line></svg>

          </div>
          <div className="party_lines party_lines_right">
          </div>
          */}
        </div>
        <nav>
          <button className={activeSection === 'schedule' ? 'active_section' : ''} onClick={() => setActiveSection('schedule')} type="button">Schedule</button>
          <button className={activeSection === 'mardi-gras' ? 'active_section' : ''} onClick={() => setActiveSection('mardi-gras')} type="button">Mardi Gras</button>
          {/* <button className={activeSection === 'things-to-do' ? 'active_section' : ''} onClick={() => setActiveSection('things-to-do')} type="button">Things to do in New Orleans</button> */}
          <button className={activeSection === 'accommodations' ? 'active_section' : ''} onClick={() => setActiveSection('accommodations')} type="button">Accommodations</button>
          <button className={activeSection === 'city-map' ? 'active_section' : ''} onClick={() => setActiveSection('city-map')} type="button">City Map</button>
        </nav>
        <hr/>
      </header>
      <section className={`party_section party_timeline${activeSection === 'schedule' ? '' : ' hidden'}`}>
        <ul >
        <li className="party_timeline_day" key="mardi-gras">
            <section className="party_timeline_day_details">
              <header>The Week Beforehand</header>
              <ul>
                <li key="mardi-gras-welcome">
                  <h2>Mardi Gras!</h2>
                  <p><button onClick={() => setActiveSection('mardi-gras')}>Click here</button> for details (and let us know if you're coming!)</p>
                </li>
              </ul>
              <div className="full_width_bg"></div>
            </section>
          </li>
        <li className="party_timeline_day" key="march-4">
            <section className="party_timeline_day_details">
              <header>Friday, <span>March 4</span></header>
              <ul>
                <li key="march-4-welcome">
                  <h2>Welcome Drinks | 8pm - &apos;till</h2>
                  <h3>GrowDat Youth Farm</h3>
                  <p>150 Zachary Taylor Dr, New Orleans, LA 70124</p>
                  <p><strong>Attire:</strong> Casual. The event will be outdoors, so please dress accordingly. Proof of vaccination and relevant boosters will be requested upon entry.</p>
                </li>
              </ul>
              <div className="full_width_bg"></div>
            </section>
          </li>
          <li className="party_timeline_day" key="march-5">
            <section className="party_timeline_day_details">
              <header>Saturday, <span>March 5</span></header>
              <ul>
                <li className="next_to_secondline" key="march-5-happy-hour">
                  <h2>Family Happy Hour | 5 - 6pm</h2>
                  <p>Family members - please join us for drinks and appetizers before the big party! </p>
                  <h3>Felicity Church</h3>
                  <p>1220 Felicity St, New Orleans, LA 70130</p>
                </li>
                <li className="next_to_secondline" key="march-5-celebration">
                  <h2>Let&apos;s Party! | 6 - 10pm</h2>
                  <h3>Felicity Church</h3>
                  <p>1220 Felicity St, New Orleans, LA 70130</p>
                  <p><strong>Attire:</strong> Cocktail. The color white is welcome as Taylor will be wearing gold. Sparkles are encouraged. Event will be indoor/outdoor. Proof of vaccination and relevant boosters will be requested upon entry.</p>
                </li>
              </ul>
              <div className="full_width_bg"></div>
            </section>
            <aside className="party_timeline_day_aside party_secondline">
              <img src="images/secondline_cutout.png"/>
            </aside>
          </li>
          <li className="party_timeline_day" key="march-6">
            <section className="party_timeline_day_details">
              <header>Sunday, <span>March 6</span></header>
              <ul>
                <li key="march-6-brunch">
                  <h2>Bayou Beverages and Goodbyes | 11am - till</h2>
                  <h3>Billings Household</h3>
                  <p>1448 Moss St, New Orleans, LA 70119</p>
                  <p>Attire: Casual. Bring a swimsuit and towel if you would like to swim.</p>
                </li>
              </ul>
              <div className="full_width_bg"></div>
            </section>
          </li>
        </ul>
        <div className="party_circle_overlay">
          {loadedCircles}
        </div>
        </section>
      <section className={`party_section mardi_gras${activeSection === 'mardi-gras' ? '' : ' hidden'}`}>
        <header><h2>Mardi Gras</h2></header>
        <p>
        Mardi Gras is the week prior to our party, ending with Fat Tuesday on March 1, 2022.
          <italic> This generally means that flights and lodging will be their busiest (and most expensive) February 24 to March 1.
          </italic>
        </p>
        <p>Costume parties and buffoonery are expected the week of Mardi Gras, and all guests are welcome to take part. Please let us know if you plan on coming!
        </p>
        <p>If you would like to join, we recommend you book transportation early:
        </p>
        <ul>
          <li>For a longer Mardi Gras experience and the least expensive travel fares, arrive Wednesday or Thursday (February 23 or 24).
          </li>
          <li>For a shorter Mardi Gras experience (and maybe more expensive fares), arrive Saturday or Sunday (February 26 or 27).</li>
        </ul>
        {/* <div className="party_circle_overlay">
          {loadedCircles}
        </div> */}
      </section>
      <section className={`party_section accommodations${activeSection === 'accommodations' ? '' : ' hidden'}`}>
        <header><h2>Accommodations</h2></header>
        <p>We&apos;ve arranged a hotel block at the <a href="https://thepontchartrainhotel.com">Pontchartrain Hotel</a> for the weekend of our party. You can reserve at <a href="https://res.windsurfercrs.com/ibe/details.aspx?propertyid=16175&nights=2&checkin=03/04/2022&group=1358812">this link</a>.</p>
        <h3>AirBnBs</h3>
        <p>There are plenty of AirBnB/Vrbo/Homeaway rentals here in New Orleans. <a href={'https://abnb.me/e/Wq7jcXkwVkb'}>Here are some options</a> to make your search easier.</p>
        <p>Note: While other options are limited, AirBnB has been particularly disruptive in NOLA. If you can rent a private room or two (as opposed to an entire house) or check out a local BNB, please do so :) </p>
        {/* <div className="party_circle_overlay">
          {loadedCircles}
        </div> */}
      </section>
      <section className={`party_section accommodations${activeSection === 'city-map' ? '' : ' hidden'}`}>
        <header><h2>City Map</h2></header>

        <iframe className="google-map" src="https://www.google.com/maps/d/embed?mid=1T703vPY5CQVD2Xs63k3O1HnQrgyoOrYg" width="640" height="480"></iframe>
      </section>
    </section>
  );
}

export default Party;
