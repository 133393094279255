import React from 'react';

function Homepage() {
  return <div className='wedding-page'>
  <figure>
    <img src="images/secondline_us.jpg"/>
    <figcaption className="title-section">
      <h2>We got married!</h2>
      <p>
        We were married on February 20th, 2021 on Magnolia Bridge in New Orleans.
        We had a perfect day, surrounded by close friends and family.
      </p>
      <p>You can check out photos from our day <a href="https://modavisphotography.passgallery.com/-davidtaylor/gallery">here</a>.</p>
      <span className="credit">credit: <a href="https://modavisphotography.com/">Mo Davis Photography</a></span>
    </figcaption>
    </figure>
  </div>;
}

export default Homepage;
