/* eslint-env browser */

// stupid styles for now
import './components/container.scss';
import './components/homepage.scss';
import './components/menu.scss';
import './components/party.scss';
import './components/wedding.scss';
import './components/rsvp.scss';
import './components/column/index.scss';
import './components/column/photo.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import Container from './components/container';

ReactDOM.render((<Container></Container>), document.querySelector('#global-container'));
